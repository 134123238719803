var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"applyInfo"},[_c('div',[_c('van-icon',{attrs:{"name":"revoke","size":"26"},on:{"click":function($event){return _vm.$router.go(-1)}}})],1),_vm._m(0),_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.list),function(item,index){return _c('van-swipe-cell',{key:index,staticStyle:{"margin":"15px 0"},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('van-button',{staticClass:"delete-button",staticStyle:{"height":"100%"},attrs:{"size":"small","square":"","text":"催办","type":"info"},on:{"click":function($event){return _vm.SetUrgeBtn(item)}}}),_c('van-button',{staticClass:"delete-button",staticStyle:{"height":"100%"},attrs:{"size":"small","square":"","text":"撤销","type":"danger"},on:{"click":function($event){return _vm.DelApplyBtn(item)}}})]},proxy:true}],null,true)},[_c('div',{staticStyle:{"height":"40px","line-height":"20px"},on:{"click":function($event){_vm.$Dialog
            .alert({
              title: item,
              message: item,
            })
            .then(function () {
              // on close
            })}}},[_c('div',{staticClass:"flexLR",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"flexLR",staticStyle:{"width":"100%","justify-content":"left","height":"100%"}},[_c('img',{attrs:{"src":require("../../../assets/mobileImg/Group 286.png"),"alt":""}}),_c('div',{staticClass:"flexcolumn",staticStyle:{"height":"100%","width":"100%","align-items":"flex-start","margin-left":"10px"}},[_c('div',{staticClass:"nowrap",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(item.Msg)+" ")]),_c('div',{staticStyle:{"color":"#a2a2a2"}},[_vm._v("时间："+_vm._s(item.Time))])]),_c('div',{staticStyle:{"width":"20%"}},[_vm._v("未通过")])])])])])}),1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding":"20px 20px 0 20px"}},[_c('span',{staticClass:"decoration",staticStyle:{"font-size":"14px"}},[_vm._v("申请记录")])])}]

export { render, staticRenderFns }