<template>
  <div class="applyInfo">
    <div>
      <van-icon name="revoke" @click="$router.go(-1)" size="26" />
    </div>
    <div style="padding: 20px 20px 0 20px">
      <span class="decoration" style="font-size: 14px">申请记录</span>
    </div>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <van-swipe-cell
        style="margin: 15px 0"
        v-for="(item, index) in list"
        :key="index"
      >
        <div
          @click="
            $Dialog
              .alert({
                title: item,
                message: item,
              })
              .then(() => {
                // on close
              })
          "
          style="height: 40px; line-height: 20px"
        >
          <div class="flexLR" style="height: 100%">
            <div
              class="flexLR"
              style="width: 100%; justify-content: left; height: 100%"
            >
              <img src="../../../assets/mobileImg/Group 286.png" alt="" />
              <div
                style="
                  height: 100%;
                  width: 100%;
                  align-items: flex-start;
                  margin-left: 10px;
                "
                class="flexcolumn"
              >
                <div class="nowrap" style="width: 100%">
                  {{ item.Msg }}
                </div>
                <div style="color: #a2a2a2">时间：{{ item.Time }}</div>
              </div>
              <div style="width: 20%">未通过</div>
            </div>
          </div>
        </div>
        <template #right>
          <van-button
            size="small"
            style="height: 100%"
            square
            text="催办"
            @click="SetUrgeBtn(item)"
            type="info"
            class="delete-button"
          />
          <van-button
            size="small"
            @click="DelApplyBtn(item)"
            style="height: 100%"
            square
            text="撤销"
            type="danger"
            class="delete-button"
          />
          <!-- <van-button
              size="small"
              style="height:100%"
              square
              text="删除"
              type="danger"
              class="delete-button"
            /> -->
        </template>
      </van-swipe-cell>
    </van-list>
  </div>
</template>

<script>
import { GetApplyRecord, SetUrge, DelApply } from "@/api";
export default {
  name: "applyInfo",
  components: {},
  data() {
    return {
      loading: false,
      finished: false,
      list: [],
    };
  },
  computed: {},
  watch: {},
  methods: {
    onConfirmProject() {},
    async onLoad() {
      this.loading = true;
      let res = await GetApplyRecord({
        params: {
          type: this.$route.params.type || 1,
        },
      });
      this.list = res.Data;

      this.loading = false;
      this.finished = true;
    },
    async SetUrgeBtn(v) {
      let res = await SetUrge({ applyid: v.Id, state: 1 });
      this.$CodeMessage(res,'催办成功');
      this.onLoad();
    },
    async DelApplyBtn(v) {
      let res = await DelApply({ applyid: v.Id });
      this.$CodeMessage(res,'撤销成功');
      this.onLoad();
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang='scss' scoped>
.applyInfo {
  padding: 40px;
  height: 100vh;
  width: 100%;
  background-color: rgb(255, 255, 255);
}
</style>